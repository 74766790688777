<template>
	<div class="PCShoppingMall">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;" class="flex">
						<span style="font-size: 0.3rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚电商平台</span>
						<span
							style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;margin-top: 0.30rem;">美出特色，精出品质，一切为了商家更好的营销</span>
						<div class="sqsy"  @click="customerService = true">咨询购买</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img style="width: 3.11rem;" src="../assets/img/PCShoppingMallbanner.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="Home">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">力瀚高端首页 专为一流电商平台打造</div><br />
					<div class="worry-tit-b">力瀚全新的首页风格，以用户为中心，带来更加便捷完善的使用体验，稳步提升成交率，
						专业美化设计，完全符合现代商城特色，细微处尽显匠心</div>
				</div>
			</div>

			<div class="Home-box">
				<div class="Home-box-T flex">
					<div class="flex" style="flex-direction: column;">
						<el-button round style="box-shadow: 0px 1px 9px 1px rgba(116, 116, 116, 0.2);color: #2E8AF9;margin-bottom: 0.3rem;">节日电商平台
						</el-button>
						<img src="../assets/img/PCShoppingMall-Home1.png">
					</div>
					<div  class="flex" style="flex-direction: column;">
						<el-button round style="box-shadow: 0px 1px 9px 1px rgba(116, 116, 116, 0.2);color: #2E8AF9;margin-bottom: 0.3rem;">通用电商平台
						</el-button>
						<img src="../assets/img/PCShoppingMall-Home2.png">
					</div>
				</div>
			</div>
		</div>

		<div class="Template">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">多套首页可视化模板，随心更换</div><br />
					<div class="worry-tit-b">商家选择一套心仪的模板，通过简单的拖动、所见即所得的简单操作，
						即可轻松打造一套自己最满意的首页效果
					</div>
				</div>
			</div>

			<div class="Template-box flex">
				<div style="flex: 1;" class="Template-box-L">
					<el-carousel :interval="5000" arrow="always">
						<el-carousel-item v-for="item in 4" :key="item">
							<img class="Template-box-L-img" src="../assets/img/PCShoppingMall-Template-L.png">
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="Template-box-R">
					<img style="width: 100%;" src="../assets/img/PCShoppingMall-Template-R.png">
					<div style="color: #666666;font-size: 0.10rem;text-align: center;">购买高级以上授权的用户可免费享有6套可视化模板</div>
					<div class="But" @click="customerService = true">咨询购买</div>
				</div>

			</div>
		</div>

		<div class="visualization">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">通过可视化编辑 快速打造专属首页</div><br />
					<div class="worry-tit-b">力瀚精美首页，可以在后台可视化自由编辑，所见即所得，快速装修自己喜欢的首页风格
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 8.32rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-visualization.png">
			</div>
		</div>

		<div class="rushbuy">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">限时秒杀抢购 促进商品销量</div><br />
					<div class="worry-tit-b">聚焦平台热门商品，放到首页秒杀模块，吸引超高人气，促进销量，每天可设置多个时段进行秒杀
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 13.49rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-rushbuy.png">
			</div>
		</div>

		<div class="visualization">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">大气的顶级分类频道</div><br />
					<div class="worry-tit-b">导航商品分类清晰，全屏banner 更显档次，楼层更清晰，给您一种网站首页的感觉，让您轻松拥有Ｎ个小分站
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 11.10rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-channel.png">
			</div>
		</div>

		<div class="rushbuy">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">平台商品-清晰显示 详细展示</div><br />
					<div class="worry-tit-b">力瀚充分考虑用户体验，将商品的展示与搜索筛选，每个小细节都精雕细琢
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;" class="flex">
				<div style="width: 6.36rem;">
					<img style="width: 100%;" src="../assets/img/PCShoppingMall-detail-L.png">
					<div style="color: #333333;font-size: 0.12rem;margin-top: 0.10rem;">商品列表页</div>
				</div>
				<div style="margin-left: 0.40rem;width: 6.36rem;">
					<img style="width: 100%;" src="../assets/img/PCShoppingMall-detail-R.png">
					<div style="color: #333333;font-size: 0.12rem;margin-top: 0.40rem;">商品详情页</div>
				</div>
			</div>
		</div>

		<div class="visualization">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">图文展示的品牌专区</div><br />
					<div class="worry-tit-b">力瀚平台拥有单独的品牌专区展示页，并且采用图文混排，让品牌展示更加大气
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 11.99rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-brand.png">
			</div>
		</div>

		<div class="rushbuy">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">更简化的一站式购物流程</div><br />
					<div class="worry-tit-b">力瀚对网上结算的购物流程进行了进一步优化，从下单、批量结算，再到送货方式匹配、加入关注等功能一气呵成，从技术和版面上改进了“一站式购物”的用户体验
					</div>
				</div>
			</div>
			<div style=" margin: 0 auto;margin-top: 0.60rem;width: 90%;" class="flex">
				<div class="rushbuy-cell">
					<div class="rushbuy-cell-l">
						<img style="width: 0.47rem;" src="../assets/img/PCShoppingMall-rushbuy-icon1.png">
						<div class="rushbuy-cell-l-tit">01</div>
						<div class="rushbuy-cell-l-lab">加入购物车</div>
					</div>
				</div>
				<div style="color: #C5C5C5;padding: 0 0.30rem;font-size: 0.14rem;">
					>>
				</div>
				<div class="rushbuy-cell">
					<div class="rushbuy-cell-l">
						<img style="width: 0.47rem;" src="../assets/img/PCShoppingMall-rushbuy-icon2.png">
						<div class="rushbuy-cell-l-tit">02</div>
						<div class="rushbuy-cell-l-lab">批量结算</div>
					</div>
				</div>
				<div style="color: #C5C5C5;padding: 0 0.30rem;font-size: 0.14rem;">
					>>
				</div>
				<div class="rushbuy-cell">
					<div class="rushbuy-cell-l">
						<img style="width: 0.47rem;" src="../assets/img/PCShoppingMall-rushbuy-icon3.png">
						<div class="rushbuy-cell-l-tit">03</div>
						<div class="rushbuy-cell-l-lab">选择支付方式</div>
					</div>
				</div>
				<div style="color: #C5C5C5;padding: 0 0.30rem;font-size: 0.14rem;">
					>>
				</div>
				<div class="rushbuy-cell">
					<div class="rushbuy-cell-l">
						<img style="width: 0.47rem;" src="../assets/img/PCShoppingMall-rushbuy-icon4.png">
						<div class="rushbuy-cell-l-tit">04</div>
						<div class="rushbuy-cell-l-lab">选择支付方式</div>
					</div>
				</div>
			</div>
		</div>

		<div class="visualization">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">购物车订单智能拆分</div><br />
					<div class="worry-tit-b">当购物车加入了不同商家店铺的商品时，平台会自动进行拆分，并产生不同的订单，便于平台和商家管理
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 12.69rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-order.png">
			</div>
		</div>

		<div class="rushbuy">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">全新会员中心 专为用户打造</span><br />
						<div class="worry-tit-b" style="margin-top: 0.30rem;">
							一个实用舒适的会员中心，可以有效黏住用户。力瀚的会员中心汇集了众多实用模块，不仅能查看订单、积分、留言、评论，更能跟踪包裹、查看浏览历史，专为您的用户打造
						</div>
					</div>
				</div>
				<div style="margin: 0 auto;margin-top: 0.60rem;width: 14.50rem;">
					<img style="width: 100%;" src="../assets/img/PCShoppingMall-center.png">
				</div>
			</div>
		</div>
		
		<div class="visualization">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<div class="worry-tit-t">手机访问智能响应浏览页面</div><br />
					<div class="worry-tit-b">搭配微商城，效果更佳
					</div>
				</div>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;width: 11.38rem;">
				<img style="width: 100%;" src="../assets/img/PCShoppingMall-intelligence.png">
			</div>
		</div>
		
		<div class="programme">
			<div class="programme-tit">支持私有化部署，满足定制开发需求</div>
			<div class="programme-but flex">
				<div class="programme-but-input">打造专属PC商城，力瀚更懂你的需求</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击定制</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data () {
			return {
				applyFromshow: false,
				customerService: false,
			}
		},
		components: {
		    // Canvas，
			applyFrom,
			customerService
		  },
		methods:{
			close(){
				this.applyFromshow = false;
			},
			
			closePopup(){
				this.customerService = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width:750px) {
		.Home-box{
			width: 100% !important;
		}
		.Home-box-B{
			width: 100% !important;
		}
		.Template-box{
			width: 100% !important;
		}
		.Template-box-R{
			width: 50% !important;
		}
		.Template-box-L-img{
			width: 60% !important;
		}
		.banner-body {
			width: 95% !important;
			margin: 0 auto;
		}
		
		.banner-body-title {
			width: 50% !important;
		
			.banner-body-title-span {
				width: 100% !important;
			}
		}
		
		.banner-body-img {
			width: 50% !important;
		}
		
	}
	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;
	
		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;
	
			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
			}
	
			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;
	
				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.50rem;
					border-radius: 0.06rem;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}
	
		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.characteristic-tit {
		// width: 40%;
		margin: 0 auto;
		text-align: center;
		line-height: 0.30rem;

		.worry-tit-t {
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333;
		}

		.worry-tit-b {
			font-size: 0.12rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #666;

		}
	}

	.But {
		width: 1.88rem;
		height: 0.50rem;
		background: linear-gradient(90deg, #2E89F9, #06D8EE);
		border-radius: 1px;
		margin: 0 auto;
		font-size: 0.18rem;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 0.50rem;
	}

	.Home {
		width: 100%;
		padding: 0.60rem 0;

		.Home-box-T {
			width: 100%;
			justify-content: space-around;
		}

		.Home-box {
			width: 13.91rem;
			margin: 0 auto;
			margin-top: 0.60rem;
			.Home-box-B{
				// width: 13.91rem;
				display: flex;
			}
		}
	}

	.Template {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.Template-box {
			// width: 50%;
			width: 15.00rem;
			margin: 0 auto;
			margin-top: 0.60rem;
			
			.Template-box-L{
				.Template-box-L-img{
					width: 7.19rem;
				}
			}
			
			.el-carousel{
				height: 5.60rem;
			}
			
			

			.Template-box-R {
				width: 7.22rem;
				.But {
					height: 0.30rem;
					margin-top: 0.20rem;
					line-height: 0.30rem;
					font-size: 0.14rem;
				}
			}
		}
	}

	.visualization {
		width: 100%;
		padding: 0.60rem 0;
	}

	.rushbuy {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;
		justify-content: space-between;

		.rushbuy-cell {

			// width: 20%;
			// flex-direction: column;
			.rushbuy-cell-l {
				background-color: #fff;
				padding: 0.20rem 0.40rem;

				.rushbuy-cell-l-tit {
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #C5C5C5;
					
				}

				.rushbuy-cell-l-lab {
					font-size: 0.14rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-top: 0.10rem;
					text-align: right;
				}
			}
		}
	}
	
	.programme{
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);
		.programme-tit{
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}
		
		.programme-but{
			// width: 40%;
			margin: 0 auto;
			.programme-but-input{
				width: 3.20rem;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
			}
			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
<style type="text/css">
	
	.el-carousel__indicator--horizontal .el-carousel__button {
		background-color: #ccc;
	}

	.el-carousel__indicator--horizontal .el-carousel__button {
		background-color: #2E89F9;
	}
	
	.el-carousel__container{
		height: 5.00rem !important;
	}
	.el-carousel__indicator--horizontal{
		padding: 0.12rem 0.04rem !important;
	}
	.el-carousel__button{
		width: 0.3rem !important;
	}
	
	.el-carousel__item {
	    display: flex !important;
	    align-items: center;
	    justify-content: center;
	}
	
	@media screen and (max-width:540px) {
		.el-carousel__container{
			height: 3.00rem !important;
		}
		.Template .Template-box .el-carousel{
			height: 4rem !important;
		}
	}
</style>
